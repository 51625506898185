import {
    createForm,
    getData,
    getDataFormToObject,
    getElement,
    getInstance,
} from "../helpers";

class ExportButton extends HTMLButtonElement {
    connectedCallback() {
        this.test();
        this.load();
        this.events();
    }

    test() {
        // No borrar este método, pero sí su contenido
    }

    load() {
        //
    }

    events() {
        this.addEventListener("click", () => {
            const Form = getElement(getData(this, "data-form"));
            let filter = {};

            if (Form && getInstance(Form, HTMLFormElement)) {
                filter = getDataFormToObject(Form, filter);
            }

            createForm(
                getData(this, "data-url"),
                filter,
                "dinamicForm",
                "POST",
                true,
                getData(this, "target")
            );
        });
    }
}

window.customElements.define("export-button", ExportButton, {
    extends: "button",
});
