import tippy from "tippy.js";
import {
    CSRF,
    alertHTML,
    createForm,
    getData,
    getElement,
    resetSelect2Ajax,
} from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import Swal from "sweetalert2/dist/sweetalert2";

class SiiSaleInvoiceModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const siiButton = getElement("[name=siibutton]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        siiButton.addEventListener("click", () => {
            if (getData(siiButton, "data-cert")) {
                Swal.fire({
                    title: getData(siiButton, "data-title"),
                    text: getData(siiButton, "data-text"),
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#198754",
                    cancelButtonColor: "#3085d6",
                    iconColor: "#87adbd",
                    confirmButtonText: getData(siiButton, "data-send"),
                    cancelButtonText: getData(siiButton, "data-cancel"),
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const tbody =
                            DtElement.getElementsByTagName("tbody")[0];
                        let ids = [];
                        tbody.childNodes.forEach((row) => {
                            if (row instanceof HTMLTableRowElement) {
                                let check = getElement("[name=check]", row);
                                if (check.checked) {
                                    let data = JSON.parse(
                                        atob(getData(check, "data-data"))
                                    );
                                    ids.push(data.id);
                                    // if(data.invoice_type && data.transcendence)
                                    //     ids.push(data.id);
                                    // else
                                    //     alertHTML(data.ref+" : "+getData(check,"data-errorsii"));
                                }
                            }
                        });
                        if (ids.length > 0)
                            // Pendiente revisar por efectos del último ajuste en array para createForm
                            // REVISAR
                            createForm(getData(siiButton, "data-url"), {
                                inv: ids,
                            });
                    }
                });
            } else {
                alertHTML(getData(siiButton, "data-notcert"));
            }
        });

        let filtro = {
            invstart: "",
            invend: "",
            customer: "",
            sii: "0",
        };

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            scrollY: "430px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("siisaleInvoicesUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filtro;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            {
                data: "check",
                width: "2%",
                className: "text-center",
                orderable: false,
            },
            { data: "ref", width: "5%", className: "text-center" },
            {
                data: "date_register",
                width: "6%",
            },
            {
                data: "date_invoice",
                width: "6%",
            },
            { data: "customer" },
            { data: "payment_method" },
            { data: "date_receipt" },
            { data: "amount" },
            { data: "pay", width: "6%" },
            { data: "accounted", width: "6%" },
            { data: "sii", width: "6%" },
        ];

        configDt.drawCallback = () => {
            tippy(".tippy-tooltips");
        };

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.invstart = getElement("[name=invstart]", this).value;
            filtro.invend = getElement("[name=invend]", this).value;
            filtro.customer = getElement("[name=customer]", this).value;
            filtro.sii = getElement("[name=sii]", this).value;
            T.ajax.reload();
        });
        BtnReset?.addEventListener("click", () => {
            getElement("[name=invstart]", this).value = "";
            filtro.invstart = "";
            getElement("[name=invend]", this).value = "";
            filtro.invend = "";
            getElement("[name=customer]", this).value = "";
            resetSelect2Ajax(getElement("[name=customer]", this));
            filtro.customer = "";
            getElement("[name=sii]", this).value = "0";
            filtro.sii = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("siisaleinvoices-module", SiiSaleInvoiceModule, {
    extends: "section",
});
