import {
    CSRF,
    formatCurrency,
    formDataToObject,
    getData,
    getDataFormToObject,
    getElement,
    Management,
    resetFilter,
} from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { dataBank } from "../../../../components/selectBank";
import { dataCustomer } from "../../../../components/selectCustomer";
import { dataPayment } from "../../../../components/selectPaymentmethod";
import { dataProject } from "../../../../components/selectProject";

class ReceiptModule extends HTMLElement {
    async connectedCallback() {
        dataBank.companies = [Management.company_id];
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const Form = getElement("[name=filter]");

        let filter = formDataToObject(Form);
        filter.type = "=";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "370px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("receiptsUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filter;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            {
                data: "date",
                className: "text-center",
                width: "7%",
            },
            { data: "customer" },
            { data: "paymentmethod" },
            { data: "bank" },
            {
                data: "amount",
                className: "text-end",
                render: (data, type, row) => {
                    return formatCurrency(data, "decimal");
                },
            },
            { data: "received", width: "5%", className: "text-center" },
            { data: "accounted", width: "5%", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            { data: "cif", className: "d-none" },
        ];

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filter = getDataFormToObject(Form, filter);

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            filter = formDataToObject(Form);
            filter.type = "=";
            dataCustomer.companies = [];
            dataPayment.payments = [];
            dataProject.projects = [];
            dataBank.notIn = [];

            resetFilter(Form, filter);

            T.ajax.reload();
        });
    }
}

window.customElements.define("receipts-module", ReceiptModule, {
    extends: "section",
});
