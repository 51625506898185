import {
    DecimalLength,
    Management,
    alertHTML,
    tryAsyncFetch,
    b64Uri,
    bodyRequest,
    cleanFloat,
    getData,
    getElement,
    getJson,
    requestOptions,
    setSelect2Ajax,
    resetSelect2Ajax,
    formatCurrency,
} from "../../../../helpers";
import { dataBank } from "../../../../components/selectBank";
import moment from "moment/moment";
import { map } from "jquery";

export class FormSaleInvoiceModule extends HTMLElement {
    async connectedCallback() {
        dataBank.companies = [Management.company_id]; // esto se debería de omitir
        this.disabledByYear();
        this.addEvents();
        this.calcTotal();
        this.calcTaxes();
    }

    addEvents() {
        const Form = getElement("form", this);
        const TBody = getElement("tBodyLines", this);

        ["change", "aj:select2", "aj:daterangepicker"].forEach((event) => {
            this.addEventListener(event, async (e) => {
                if (e.target.name == "customer_company_id") {
                    const Customer = e.detail.data;

                    // Desplegar las cuentas
                    Form.elements.accountingplan_id.innerHTML = "";
                    Customer.accountingplans.forEach((item) => {
                        Form.elements.accountingplan_id.innerHTML = `<option value="${item.id}">${item.number} - ${item.name}</option>`;
                    });

                    // Establecer el método de pago
                    let paymentmethod = Customer.paymentmethod;
                    if (paymentmethod) {
                        setSelect2Ajax(
                            Form.elements.paymentmethod_id,
                            paymentmethod.id,
                            paymentmethod.name,
                            paymentmethod
                        );
                    }

                    // Establecer proyecto
                    let project = Customer?.with_projects?.find(
                        (item) => item.id
                    );
                    if (project) {
                        setSelect2Ajax(
                            Form.elements.project_id,
                            project.id,
                            project.name,
                            project
                        );
                    }

                    // Clacular la fecha de pago
                    let today = moment(
                        Form.elements.date_invoice.value || undefined,
                        getData(Form.elements.date_receipt, "data-format")
                    );
                    today.add(paymentmethod?.days ?? 0, "days");
                    Form.elements.date_receipt.value = today.format(
                        getData(Form.elements.date_receipt, "data-format")
                    );

                    this.clearByChangeCustomer();
                }

                if (e.target.name == "tax" || e.target.name == "withholding") {
                    this.calcLine(e.target);
                }

                if (e.target.name == "paymentmethod_id") {
                    // Establecer el método de pago
                    let paymentmethod = e.detail.data;

                    // Clacular la fecha de pago
                    let today = moment(
                        Form.elements.date_invoice.value || undefined,
                        getData(Form.elements.date_receipt, "data-format")
                    );

                    today.add(paymentmethod?.days ?? 0, "days");
                    Form.elements.date_receipt.value = today.format(
                        getData(Form.elements.date_receipt, "data-format")
                    );
                }

                this.refillLines();
                this.calcTaxes();
                await this.draft();
            });
        });

        let row = TBody.lastElementChild.cloneNode(true);

        Form.elements.new_line?.addEventListener("click", () => {
            let row2 = row.cloneNode(true);
            getElement("[name=project]", row2).removeAttribute("data-default"); // remover datos por defecto para que tome el proyecto seleccionado
            TBody.appendChild(row2);
            getElement("[name=type]", row2).value =
                "App\\Models\\Accountingplan";
            getElement("[name=quantity]", row2).value = "";
            getElement("[name=unit_price]", row2).value = "";
            getElement("[name=tax_base]", row2).value = "";
            getElement("[name=total_tax]", row2).value = "";
            getElement("[name=total_line]", row2).value = "";
            this.refillLines();
            const IptIsp = getElement("[name=isp]");
            this.setWh(IptIsp);
            this.calcTotal();
            this.calcTaxes();
        });

        Form.elements.save?.addEventListener("click", async () => {
            Array.from(Form.elements).forEach((element) => {
                if (element.classList.contains("noValidateDraft")) {
                    if (element.name === "bank") {
                        element.removeAttribute("required");
                    } else {
                        element.setAttribute("required", "");
                    }
                }
            });

            this.prepareLines();

            if (Form.reportValidity() && (await this.validateData())) {
                this.calcTaxes();
                if (
                    Form.elements.accounted?.checked ||
                    Form.elements.received?.checked
                ) {
                    Form.elements.bank.setAttribute("required", "");
                    const ModalSale = getElement("modalSaveSale", this);
                    const WarningAccounted = getElement(
                        "[name=warning_accounted]",
                        ModalSale
                    );
                    const WarningReceipt = getElement(
                        "[name=warning_receipt]",
                        ModalSale
                    );

                    WarningAccounted.classList.remove("d-none");
                    WarningReceipt.classList.remove("d-none");

                    if (!Form.elements.accounted?.checked) {
                        WarningAccounted.classList.add("d-none");
                    }

                    if (!Form.elements.received?.checked) {
                        WarningReceipt.classList.add("d-none");
                    }

                    Form.submit();
                } else {
                    Form.submit();
                }
            }
        });

        TBody.addEventListener("input", async (e) => {
            const IptType = e.target.closest("[name=type]");
            const IptQuantity = e.target.closest("[name=quantity]");
            const IptPrice = e.target.closest("[name=unit_price]");
            const IptTotal = e.target.closest("[name=total_line]");

            if (IptType) {
                const NextCol = IptType.parentElement.nextElementSibling;

                if (IptType.value == "App\\Models\\Accountingplan") {
                    NextCol.innerHTML = `<div class="input-group input-group-sm flex-nowrap mt-1" style="width: 150px">
                    <button is="accountingplan-button" type="button" class="input-group-text" data-bs-toggle="modal" data-bs-target="#modalAccountingPlan" role="button" data-url="${getData(
                        NextCol,
                        "data-url"
                    )}" title="${getData(
                        NextCol,
                        "title"
                    )}" data-message="${getData(NextCol, "data-message")}">
                        <i class="fa-solid fa-receipt"></i>
                    </button>
                    <div class="col text-truncate">
                    <select is="select-accountingplan" class="form-select form-select-sm w-100 border noValidateDraft" name="account" data-isParent="false" data-account-group="7" required></select>
                    </div>
                </div>`;
                } else {
                    NextCol.innerHTML = `<div class="input-group input-group-sm flex-nowrap mt-1" style="width: 150px">
                        <select is="select-product" class="form-select form-select-sm w-100 border noValidateDraft" name="product"  required></select>
                        </div>`;
                }
            }

            if (IptQuantity || IptPrice) {
                this.calcLine(e.target);
            }

            if (IptTotal) {
                this.calcTotal();
            }
        });

        TBody.addEventListener("cl:deleteRow", async (e) => {
            if (!TBody.children.length) {
                const BtnNewLine = getElement("[name=new_line]", this);
                BtnNewLine?.click();
            }

            this.calcTaxes();
            await this.draft();
        });

        document.body.addEventListener("aj:startYear", (e) => {
            const Form = getElement("form", this);
            let invoice = getJson(
                b64Uri(Form.elements.invoice.value, "decode")
            );

            this.disabledByYear();

            if (invoice.id) {
                let startYear = parseInt(localStorage.getItem(Management.slug));
                let dateInvoice = moment(invoice.date_invoice);
                if (dateInvoice.year() != startYear) {
                    // esto es la forma más simple aunque un poco vulgar
                    localStorage.setItem(Management.slug, dateInvoice.year());
                    window.location.reload();
                }
            }
        });

        this.addEventListener("change", (e) => {
            const IptIsp = e.target.closest("[name=isp]");

            if (IptIsp) {
                this.isp(IptIsp);
            }
        });
    }

    calcLine(target) {
        const Customer = getJson(
            b64Uri(
                getData(
                    "[name=customer_company_id]",
                    "data-data-selected",
                    this
                ),
                "decode"
            )
        );
        const Parent = target.closest("tr");
        const IptQuantity = getElement("[name=quantity]", Parent);
        const IptPrice = getElement("[name=unit_price]", Parent);
        const Tax = getJson(
            b64Uri(
                getData("[name=tax]", "data-data-selected", Parent),
                "decode"
            )
        );
        const Withholding = getJson(
            b64Uri(
                getData("[name=withholding]", "data-data-selected", Parent),
                "decode"
            )
        );
        const IptTaxBase = getElement("[name=tax_base]", Parent);
        const IptTotalWithholding = getElement(
            "[name=total_withholding]",
            Parent
        );
        const IptTotalTax = getElement("[name=total_tax]", Parent);
        const IptTotalSurcharge = getElement("[name=total_surcharge]", Parent);
        const IptTotal = getElement("[name=total_line]", Parent);
        let subTotal =
            cleanFloat(IptQuantity.value) * cleanFloat(IptPrice.value);
        let taxBase =
            subTotal *
            (cleanFloat(formatCurrency(Tax?.tax ?? 0, "decimal")) / 100);
        let withholdingBase =
            subTotal *
            (cleanFloat(formatCurrency(Withholding?.tax ?? 0, "decimal")) /
                100);

        IptTaxBase.value = formatCurrency(
            subTotal.toFixed(DecimalLength),
            "decimal"
        );
        IptTotalWithholding.value = formatCurrency(
            withholdingBase.toFixed(DecimalLength),
            "decimal"
        );
        IptTotalTax.value = formatCurrency(
            taxBase.toFixed(DecimalLength),
            "decimal"
        );

        let surcharge = 0;
        if (Customer.equivalence_surcharge) {
            surcharge =
                subTotal *
                (cleanFloat(formatCurrency(Tax?.surcharge ?? 0, "decimal")) /
                    100);
            IptTotalSurcharge.value = formatCurrency(
                surcharge.toFixed(DecimalLength),
                "decimal"
            );
        } else {
            surcharge = 0;
            IptTotalSurcharge.value = 0;
        }

        let total = subTotal - withholdingBase + taxBase + surcharge;
        IptTotal.value = formatCurrency(
            total.toFixed(DecimalLength),
            "decimal"
        );

        this.calcTotal();
    }

    calcTotal() {
        const Form = getElement("form", this);
        const TotalLines = Form.querySelectorAll("[name=total_line]");
        let total = 0;

        TotalLines.forEach((item) => {
            total += cleanFloat(item.value);
        });

        return total;
    }

    async validateData() {
        const Form = getElement("form", this);
        let validate = Form.checkValidity();
        if (validate) {
            let body = new FormData(Form);
            requestOptions.body = body;
            let response = await tryAsyncFetch(
                getData(Form, "data-url-validate"),
                requestOptions
            );

            if (response.errors) {
                validate = false;

                let msg = Object.values(response.errors)
                    .flat()
                    .flatMap((item) => `<li>${item}</li>`)
                    .join("");

                alertHTML(`<ul>${msg}</ul>`);
            }
        }

        return validate;
    }

    async draft() {
        if (this.disabledByYear()) {
            const Form = getElement("form", this);
            let invoice = getJson(
                b64Uri(Form.elements.invoice.value, "decode")
            );

            if (invoice?.error || invoice?.ref === "borrador") {
                Array.from(Form.elements).forEach((element) => {
                    if (element.classList.contains("noValidateDraft")) {
                        element.removeAttribute("required");
                    }
                });
                if (Form.checkValidity()) {
                    let body = new FormData(Form);
                    body.delete("accounted");
                    body.delete("received");
                    body.set("lines", b64Uri(this.prepareLines()));
                    requestOptions.body = body;
                    let response = await tryAsyncFetch(
                        Form.action,
                        requestOptions
                    );
                    requestOptions.body = bodyRequest; // regresar el bodyRequest predeterminado

                    if (!response.errors) {
                        Form.elements.invoice.value = b64Uri(response.data);
                        Form.setAttribute(
                            "data-url-validate",
                            getData(Form, "data-url-validate") +
                                "/" +
                                response.data.id
                        );
                    } else {
                        alertHTML(response.message, "error");
                    }
                }
            }
        }
    }

    clearByChangeCustomer() {
        const TBody = getElement("tBodyLines", this);
        // const IptIsp = getElement("[name=isp]");

        Array.from(TBody.children).forEach((child) => {
            const Account = getElement("[name=account]", child);
            const Project = getElement("[name=project]", child);
            const Description = getElement("[name=description]", child);
            const TW = getElement("[name=total_withholding]", child);
            const TT = getElement("[name=total_tax]", child);
            const TS = getElement("[name=total_surcharge]", child);
            const Tax = getElement("[name=tax]", child);

            resetSelect2Ajax(Tax);
            resetSelect2Ajax(Account);
            resetSelect2Ajax(Project);
            Description.value = "";
            TW.value = "";
            TT.value = "";
            TS.value = "";

            // if (!IptIsp.checked) {
            //     const Withholding = getElement("[name=withholding]", child);
            //     resetSelect2Ajax(Withholding);
            // }

            this.calcLine(TS);
        });
    }

    refillLines() {
        let customer = getJson(
            b64Uri(
                getData(
                    "[name=customer_company_id]",
                    "data-data-selected",
                    this
                ),
                "decode"
            )
        );

        const TBody = getElement("tBodyLines", this);
        const IptIsp = getElement("[name=isp]");

        if (customer?.outaccounting) {
            TBody.querySelectorAll("[name=account]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(
                        element,
                        customer.outaccounting.id,
                        `${customer.outaccounting.number} - ${customer.outaccounting.name}`,
                        customer.outaccounting
                    );
                }
            });
        }

        let tax = customer?.taxes?.find((item) => item.type === "IVA");
        if (tax && !IptIsp.checked) {
            TBody.querySelectorAll("[name=tax]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(element, tax.id, tax.description, tax);
                }
            });
        }

        let isp = customer?.taxes?.find((item) => item.type === "ISP");
        if (isp && IptIsp.checked) {
            TBody.querySelectorAll("[name=tax]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(element, isp.id, isp.description, isp);
                }
            });
        }

        let withholding = customer?.taxes?.find(
            (item) => item.type === "Retenciones"
        );
        if (withholding && !IptIsp.checked) {
            TBody.querySelectorAll("[name=withholding]")?.forEach((element) => {
                if (!element.value) {
                    setSelect2Ajax(
                        element,
                        withholding.id,
                        withholding.description,
                        withholding
                    );
                }
            });
        }

        // let project = customer?.with_projects?.find((item) => item.id);
        let project = getJson(
            b64Uri(getData("[name=project_id]", "data-data-selected"), "decode")
        );
        if (project) {
            TBody.querySelectorAll("[name=project]")?.forEach((element) => {
                if (!element.value || element.value == 30) {
                    setSelect2Ajax(element, project.id, project.name, project);
                }
            });
        }

        if (customer) {
            TBody.querySelectorAll("[name=description]")?.forEach((element) => {
                if (!element.value) {
                    element.value = "S/FRA - " + customer?.text;
                }
            });
        }
    }

    isp(element) {
        let customer = getJson(
            b64Uri(
                getData(
                    "[name=customer_company_id]",
                    "data-data-selected",
                    this
                ),
                "decode"
            )
        );
        const TBody = getElement("tBodyLines", this);

        let wh = getJson(b64Uri(getData(element, "data-wh"), "decode"));

        TBody.querySelectorAll("[name=withholding]")?.forEach((item) => {
            if (element.checked) {
                item.parentElement.classList.add("same-disabled");
                setSelect2Ajax(item, wh.id, wh.description, wh);
            } else {
                item.parentElement.classList.remove("same-disabled");
            }
        });

        let isp = customer?.taxes?.find((item) => item.type === "ISP");
        let tax = customer?.taxes?.find((item) => item.type === "IVA");

        TBody.querySelectorAll("[name=tax]")?.forEach((item) => {
            resetSelect2Ajax(item);
            if (element.checked) {
                item.setAttribute("data-type", "ISP");
                if (isp) {
                    setSelect2Ajax(item, isp.id, isp.description, isp);
                }
            } else {
                item.setAttribute("data-type", "IVA");
                if (tax) {
                    setSelect2Ajax(item, tax.id, tax.description, tax);
                }
            }
        });
    }

    setWh(element) {
        const TBody = getElement("tBodyLines", this);

        let wh = getJson(b64Uri(getData(element, "data-wh"), "decode"));

        TBody.querySelectorAll("[name=withholding]")?.forEach((item) => {
            if (element.checked) {
                item.parentElement.classList.add("same-disabled");
                setSelect2Ajax(item, wh.id, wh.description, wh);
            } else {
                item.parentElement.classList.remove("same-disabled");
            }
        });
    }

    prepareLines(taxes = false) {
        const Form = getElement("form", this);
        const TBody = getElement("tBodyLines", this);
        let dataForm = [];

        Array.from(TBody.children).forEach((item) => {
            const IptType = getElement("[name=type]", item);
            const IptAccount = getJson(
                b64Uri(
                    getData("[name=account]", "data-data-selected", item),
                    "decode"
                )
            );
            const IptProduct = getJson(
                b64Uri(
                    getData("[name=product]", "data-data-selected", item),
                    "decode"
                )
            );
            const IptQuantity = getElement("[name=quantity]", item);
            const IptPrice = getElement("[name=unit_price]", item);
            const Tax = getJson(
                b64Uri(
                    getData("[name=tax]", "data-data-selected", item),
                    "decode"
                )
            );
            const Withholding = getJson(
                b64Uri(
                    getData("[name=withholding]", "data-data-selected", item),
                    "decode"
                )
            );
            const IptSurcharge = getElement("[name=total_surcharge]", item);
            const IptTotalLine = getElement("[name=total_line]", item);
            const description = getElement("[name=description]", item);
            const project = getElement("[name=project]", item);

            const data = {
                element_type: IptType.value,
                element_id: IptAccount?.id ?? IptProduct?.id,
                description: description.value,
                quantity: cleanFloat(IptQuantity.value),
                unit_price: cleanFloat(IptPrice.value),
                total_line: cleanFloat(IptTotalLine.value),
                project_id: project.value,
                tax_id: Tax?.id,
                tax: Tax,
                withholding_id: Withholding?.id,
                withholding: Withholding,
                surcharge: cleanFloat(IptSurcharge.value),
            };

            dataForm.push(data);
        });

        if (taxes) {
            dataForm = dataForm.filter(
                (item) =>
                    item.quantity &&
                    item.unit_price &&
                    (item.tax_id || item.withholding_id)
            );
        } else {
            dataForm = dataForm.filter(
                (item) =>
                    item.element_type &&
                    item.element_id &&
                    item.quantity &&
                    item.unit_price &&
                    item.total_line &&
                    item.project_id &&
                    item.tax_id &&
                    item.withholding_id
            );
        }

        Form.elements.lines.value = b64Uri(dataForm);

        return dataForm;
    }

    calcTaxes() {
        const CntTaxes = getElement("cntTaxes", this);
        CntTaxes.innerHTML = "";

        const Customer = getJson(
            b64Uri(
                getData(
                    "[name=customer_company_id]",
                    "data-data-selected",
                    this
                ),
                "decode"
            )
        );

        let lines = this.prepareLines(true);
        lines.sort((a, b) => parseFloat(a.tax.tax) - parseFloat(b.tax.tax));

        let taxes = lines.reduce((acc, item) => {
            if (item.tax.id) {
                let index = acc.findIndex((i) => i.id == item.tax.id);

                item.tax.tax_base = item.quantity * item.unit_price;
                item.tax.total_tax =
                    item.tax.tax_base *
                    (cleanFloat(formatCurrency(item.tax.tax, "decimal")) / 100);
                if (index >= 0) {
                    acc[index].tax_base += item.tax.tax_base;
                    acc[index].total_tax += item.tax.total_tax;
                } else {
                    acc.push(item.tax);
                }
            }
            return acc;
        }, new map());

        taxes.forEach((item) => {
            CntTaxes.innerHTML += `<div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${
                                                item.description
                                            }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.tax_base.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.total_tax.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>`;

            if (Customer.equivalence_surcharge) {
                let ts =
                    item.tax_base *
                    (cleanFloat(formatCurrency(item.surcharge, "decimal")) /
                        100);
                CntTaxes.innerHTML += `<div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="Recargo sobre ${
                                                item.description
                                            }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.tax_base.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                ts.toFixed(DecimalLength),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>`;
            }
        });

        //Parche por implementación de retenciones
        lines.sort(
            (a, b) =>
                parseFloat(a.withholding.tax) - parseFloat(b.withholding.tax)
        );

        let withholdings = lines.reduce((acc, item) => {
            if (item.withholding.id) {
                let index = acc.findIndex((i) => i.id == item.withholding.id);

                item.withholding.tax_base = item.quantity * item.unit_price;
                item.withholding.total_withholding =
                    item.withholding.tax_base *
                    (cleanFloat(
                        formatCurrency(item.withholding.tax, "decimal")
                    ) /
                        100);
                if (index >= 0) {
                    acc[index].tax_base += item.withholding.tax_base;
                    acc[index].total_withholding +=
                        item.withholding.total_withholding;
                } else {
                    acc.push(item.withholding);
                }
            }
            return acc;
        }, new map());

        withholdings.forEach((item) => {
            CntTaxes.innerHTML += `<div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${
                                                item.description
                                            }" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.tax_base.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group input-group-sm flex-nowrap mt-1">
                                            <input type="text" class="form-control form-control-sm" data-number disabled value="${formatCurrency(
                                                item.total_withholding.toFixed(
                                                    DecimalLength
                                                ),
                                                "decimal"
                                            )}" />
                                        </div>
                                    </div>`;
        });

        const Form = getElement("form", this);
        let total = this.calcTotal();

        Form.elements.total.value = formatCurrency(
            total.toFixed(DecimalLength),
            "decimal"
        );
    }

    disabledByYear() {
        const Form = getElement("form", this);
        let startYear = parseInt(localStorage.getItem(Management.slug));
        let years = Management.years.map((obj) => obj.year);
        let maxYear = Math.max(...years);
        let disableByYear = false;

        if (maxYear - startYear > 1) {
            Form.elements.new_line?.classList.add("d-none");
            Form.elements.save?.classList.add("d-none");
            disableByYear = false;
        } else {
            Form.elements.new_line?.classList.remove("d-none");
            Form.elements.save?.classList.remove("d-none");
            disableByYear = true;
        }

        return disableByYear;
    }
}

window.customElements.define("formsaleinvoices-module", FormSaleInvoiceModule, {
    extends: "section",
});
