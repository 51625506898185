import { SelectDos } from "../plugins/select2";
import { getData, CSRF } from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para los paises
 *
 */
export class SelectLang extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("langsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        orderFirst: "alpha2",
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            templateSelection: (data) => {
                if (!data.id) {
                    return data.text;
                }
                return `${data.alpha2}`;
            },
        };

        const SD = new SelectDos(configSelect);
        const S = SD.selectDos(this);
    }

    /**
     * Asigna al evento click la funcionalidad para mostrar y ocultar la contraseña
     * así como el cambio de icono de FontAwesome
     *
     * @return {Void}
     */
    addClickEvent() {
        //
    }
}

window.customElements.define("select-lang", SelectLang, {
    extends: "select",
});
