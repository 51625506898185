import { dataProject } from "../../../components/selectProject";
import {
    alertHTML,
    bodyRequest,
    CSRF,
    DateFormat,
    formDataToObject,
    getData,
    getDataFormToObject,
    getDate,
    getElement,
    isDate,
    requestOptions,
    resetFilter,
    tryAsyncFetch,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class JournalModule extends HTMLElement {
    async connectedCallback() {
        //console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const Form = getElement("[name=filter]", this);

        let filter = formDataToObject(Form);
        filter.type = "=";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            pageLength: 50,
            ordering: false,
            scrollY: "450px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("accountingJournalUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filter;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "entryView", className: "text-center" },
            {
                data: "date",
                className: "text-center",
                render: (data, type, row, meta) => {
                    if (isDate(data)) {
                        let date = getDate(data);
                        return date.format(DateFormat);
                    } else {
                        return data;
                    }
                },
            },
            { data: "account" },
            { data: "description" },
            { data: "project" },
            { data: "debit", width: "7%", className: "text-end" },
            { data: "credit", width: "7%", className: "text-end" },
            { data: "checked", width: "5%", className: "text-center" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            filter = getDataFormToObject(Form, filter);

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", (e) => {
            filter = formDataToObject(Form);
            filter.type = "=";
            dataProject.projects = [];

            resetFilter(Form, filter);

            T.ajax.reload();
        });

        DtElement.addEventListener("click", async (e) => {
            const Target = e.target;

            if (Target.name == "checked") {
                bodyRequest.set("checked", Target.checked);

                let response = await tryAsyncFetch(
                    getData(Target, "data-url"),
                    requestOptions
                );

                if (response.error) {
                    Target.checked = !Target.checked;
                    alertHTML(response.error);
                }
            }
        });
    }
}

window.customElements.define("journal-module", JournalModule, {
    extends: "section",
});
