import $ from "jquery/dist/jquery";
// import "datatables.net/js/dataTables";
import "datatables.net-bs5/js/dataTables.bootstrap5";
// import "datatables.net-plugins/filtering/type-based/accent-neutralise";
// import 'datatables.net-plugins/filtering/type-based/diacritics-neutralise';
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-rowgroup/js/dataTables.rowGroup";
// import "datatables.net-buttons/js/buttons.print";
// import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5";
// import jszip from "jszip/dist/jszip";
// window.JSZip = jszip;
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-responsive-bs5/js/responsive.bootstrap5";
import { Management, readTranslations } from "../helpers";

export class Dt {
    #table = null;
    #show = "view.plugins.datatable.show";
    #records = "view.plugins.datatable.records";
    #zeroRecords = "view.plugins.datatable.zeroRecords";
    #emptyTable = "view.plugins.datatable.zeroRecords";
    #info = "view.plugins.datatable.info";
    #infoEmpty = "view.plugins.datatable.infoEmpty";
    #infoFiltered = "view.plugins.datatable.infoFiltered";
    #search = "view.plugins.datatable.search";
    #processing = "view.plugins.datatable.processing";

    #configDt = {
        // Ajax para mejorar velocidad
        processing: false, // Procesar ajax
        serverSide: false, // Mejorar velocidad ajax registros por página
        pageLength: 10, // Límite registrtos por página carga inicial
        paging: true, // Habilita la paginación
        lengthChange: true, // Mostrar cantidad de registros
        searching: true, // Habilitar busqueda
        ordering: true, // Habilitar ordenable
        info: true, // Muestra total de páginas
        autoWidth: false, // False ajuste para responsive
        responsive: true, // Habilitar el responsive
        pagingType: "simple_numbers",
        // Traducir
        language: {
            lengthMenu:
                "Mostrar " +
                `<select class="form-select form-select-sm">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                </select>
                ` +
                " registros",
            zeroRecords: "No se encontraron registros",
            emptyTable: "No se encontraron registros",
            info: "Página _PAGE_ de _PAGES_",
            infoEmpty: "No hay registros disponibles",
            infoFiltered: "(filtrado _TOTAL_ de _MAX_ registros)",
            search: "Buscar",
            paginate: {
                next: '<i class="fas fa-caret-right"></i>',
                previous: '<i class="fas fa-caret-left"></i>',
            },
            processing:
                "Debido a la cantidad de datos esto puede tardar un momento...",
        },
        order: [], // Prioridad de orden en columnas
        stateSave: true, // Recordar acciones
        stateDuration: 0, // Necesario cuando stateSave es true para evitar el auto-orden
    };

    constructor(object = {}) {
        this.#configDt = { ...this.#configDt, ...object };
    }

    async dataTable(table) {
        this.#table = table;
        // Traducir
        const Translations = await readTranslations();

        if (!this.#configDt.language) {
            this.#configDt.language = {};
        }

        this.#configDt.language.lengthMenu =
            Translations[this.#show] +
            " " +
            `<select class="form-select form-select-sm">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
        </select>
        ` +
            Translations[this.#records];
        this.#configDt.language.zeroRecords = Translations[this.#zeroRecords];
        this.#configDt.language.emptyTable = Translations[this.#emptyTable];
        this.#configDt.language.info = Translations[this.#info];
        this.#configDt.language.infoEmpty = Translations[this.#infoEmpty];
        this.#configDt.language.infoFiltered = Translations[this.#infoFiltered];
        this.#configDt.language.search = Translations[this.#search];
        this.#configDt.language.processing = Translations[this.#processing];

        if (this.#configDt?.ajax?.data) {
            let originalData = this.#configDt.ajax.data;

            this.#configDt.ajax.data = (data) => {
                originalData.call(this, data);

                data.year = localStorage.getItem(Management.slug);
            };
        }

        const T = $(table).DataTable(this.#configDt);

        document.body.addEventListener("aj:startYear", (e) => {
            // Recargar solo los datatables con ajax
            if (this.#configDt?.ajax) {
                T.ajax.reload();
            }
        });

        return T;
    }

    reloadData(data, ajax = false) {
        if (ajax) {
            // Esto no se está usando pendiende comprobar código de Carlos
            $(this.#table).DataTable().draw(false);
        } else {
            $(this.#table).DataTable().clear().draw();
            $(this.#table).DataTable().rows.add(data).draw();
        }
    }

    getDatatable() {
        return $(this.#table).DataTable().data().toArray();
    }
}
