import {
    CSRF,
    clearCurrency,
    formatCurrency,
    getData,
    getElement,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";

class PaymentProvisionsModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const Form = getElement("[name=formFilter]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            scrollY: "413px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        let filter = {
            paystart: null,
            payend: null,
            accounted: null,
        };

        configDt.ajax = {
            url: getData("paymentProvisionsUrlDt"),
            type: "POST",
            data: (data) => {
                data.filter = filter;
            },
            beforeSend: (request) => {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "id", width: "5%", className: "text-center" },
            {
                data: "created_at",
                width: "6%",
            },
            { data: "paymentMethod" },
            { data: "date" },
            { data: "bank" },
            { data: "amount", className: "text-end" },
            { data: "pay", width: "6%", className: "text-center" },
            { data: "accounted", width: "6%", className: "text-center" },
        ];

        configDt.footerCallback = function (row, data, start, end, display) {
            let pageTotal = data.reduce(
                (acc, item) => {
                    acc.amount = clearCurrency(acc.amount.toString());
                    item.amount = clearCurrency(item.amount.toString());
                    acc.amount += item.amount;

                    return acc;
                },
                { amount: 0 }
            );

            pageTotal = formatCurrency(pageTotal.amount);
            let api = this.api();
            if (api.column(0).footer()) {
                api.column(5).footer().textContent = pageTotal;
            }
        };

        const DtInvoices = new Dt(configDt);
        let T = await DtInvoices.dataTable(DtElement);
        // End DataTable

        Form.elements.search.addEventListener("click", () => {
            let body = new FormData(Form);
            filter = Array.from(body).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
            T.ajax.reload();
        });

        Form.elements.reset.addEventListener("click", () => {
            filter = {
                paystart: null,
                payend: null,
                accounted: null,
            };
            T.ajax.reload();
        });
    }
}

window.customElements.define(
    "paymentprovisions-module",
    PaymentProvisionsModule,
    {
        extends: "section",
    }
);
