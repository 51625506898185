import { dataProject } from "../../../components/selectProject";
import {
    CSRF,
    getData,
    getElement,
    formatCurrency,
    resetSelect2Ajax,
    cleanFloat,
    isDate,
    getDate,
    DateFormat,
    getDataFormToObject,
    resetFilter,
    formDataToObject,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class LedgerModule extends HTMLElement {
    async connectedCallback() {
        const DtElement = getElement("[data-table=dt]", this);
        const BtnAccount = getElement("[name=tipo-account]", this);
        const BtnsubAccount = getElement("[name=tipo-subaccount]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const IptType = getElement("[name=type]", this);
        const Form = getElement("[name=filter]", this);

        let filter = formDataToObject(Form);

        let type = "subaccounting";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            ordering: false,
            scrollY: "400px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.rowGroup = {
            dataSrc: "subaccountingplan_name",
            startClassName: "heading-group",
            endClassName: "footer-group",
            endRender: (rows, group) => {
                let data = rows.data().toArray();
                let debit = data.reduce((acc, item) => {
                    if (
                        item.amount != "" &&
                        item.accounting_column == "debit"
                    ) {
                        let debit = cleanFloat(
                            formatCurrency(item.amount, "decimal")
                        );
                        acc += parseFloat(debit);
                    }

                    return acc;
                }, 0);

                let credit = data.reduce((acc, item) => {
                    if (
                        item.amount != "" &&
                        item.accounting_column == "credit"
                    ) {
                        let credit = cleanFloat(formatCurrency(item.amount));
                        acc += parseFloat(credit);
                    }

                    return acc;
                }, 0);

                let balance = debit - credit;
                let label = getData(this, "data-s0");

                if (balance > 0) {
                    label = getData(this, "data-sd");
                } else if (balance < 0) {
                    label = getData(this, "data-sc");
                } else {
                    label = getData(this, "data-s0");
                }

                balance = formatCurrency(Math.abs(balance), "decimal");

                let tr = document.createElement("tr");

                addCell(tr, "Totales", 5, "text-end pe-3");
                addCell(
                    tr,
                    isNaN(debit) ? "" : formatCurrency(debit, "decimal"),
                    1,
                    "text-end"
                );
                addCell(
                    tr,
                    isNaN(credit) ? "" : formatCurrency(credit, "decimal"),
                    1,
                    "text-end"
                );
                addCell(tr, label + balance, 1, "text-end");

                return tr;
            },
        };

        configDt.ajax = {
            url: getData("ledgerUrlDt"),
            type: "POST",
            data: function (data) {
                data.projects = filter.projects;
                data.startDate = filter.startDate;
                data.endDate = filter.endDate;
                data.accountstart = filter.accountstart;
                data.accountend = filter.accountend;
                data.type = type;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "journal", width: "20%" },
            {
                data: "dateformat",
                render: (data, type, row, meta) => {
                    if (isDate(data)) {
                        let date = getDate(data);
                        return date.format(DateFormat);
                    } else {
                        return data;
                    }
                },
            },
            { data: "label" },
            { data: "project" },
            { data: "debit", width: "7%", className: "text-end" },
            { data: "credit", width: "7%", className: "text-end" },
            { data: "balance", width: "7%" },
        ];

        configDt.initComplete = function (settings, json) {
            // esta es una solución para la actualización de los paquetes
            // Este código se ejecuta una vez que DataTable ha terminado de cargar completamente
            // Aquí puedes realizar cualquier ajuste adicional que necesites
            const footerRows =
                settings.nTable.querySelectorAll(".footer-group");

            footerRows.forEach((row) => {
                Array.from(row.children).forEach((child, index) => {
                    child.colSpan = 1;

                    if (index == 0) {
                        child.colSpan = 5;
                    }
                });
            });
        };

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        //Acciones
        BtnAccount?.addEventListener("click", () => {
            IptType.value = type = "accounting";
            T.rowGroup().dataSrc("accountingplan_name");
            T.ajax.reload();
        });
        BtnsubAccount?.addEventListener("click", () => {
            IptType.value = type = "subaccounting";
            T.rowGroup().dataSrc("subaccountingplan_name");
            T.ajax.reload();
        });

        BtnSearch?.addEventListener("click", () => {
            filter = getDataFormToObject(Form, filter);

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            filter = formDataToObject(Form);
            dataProject.projects = [];

            resetFilter(Form, filter);
            
            T.ajax.reload();
        });

        function addCell(tr, content, colSpan = 1, className = "") {
            let td = document.createElement("th");
            td.colSpan = colSpan;
            td.textContent = content;
            td.className = className;
            tr.appendChild(td);
        }
    }
}

window.customElements.define("ledger-module", LedgerModule, {
    extends: "section",
});
