import $ from "jquery/dist/jquery";
import Swal from "sweetalert2/dist/sweetalert2";
import tippy from "tippy.js";
import moment from "moment";
import inputmask from "inputmask";

/**
 * Enviar el focus al elemento objetivo,
 * si no se envía buscará el primer elemento con la clase setFocus
 *
 * @param {*} target Identificador, clase o elemento HTML
 */
export const setFocus = (target) => {
    target = getElement(target);

    target?.focus();
};

/**
 * Función que retorna el tipo de dato del argumento pasado como parámetro
 *
 * @param {*} data valor a determinar el tipo de dato
 * @returns {string} devuelve una cadena con el tipo de dato en minúscula
 */
export const getType = (data) => {
    if (Array.isArray(data)) {
        return "array";
    } else if (typeof data === "object" && data !== null) {
        return "object";
    } else {
        return typeof data;
    }
};

/**
 * PENDIENTE COMPROBAR USABILIDAD
 * Recibe dos objetos para comparar, recorriendo el primer objeto y verifica si sus propiedades
 * existen en el segundo objeto en caso de que no existan se las asignará.
 *
 * @param {Obejct} object1 argumeno de tipo objeto
 * @param {Obejct} object2 argumeno de tipo objeto
 * @param {Boolean} Mostrar warnings para depurar.
 */
export const setDefaultAttributes = (object1, object2, debug = false) => {
    if (getType(object1) === "object" && getType(object2) === "object") {
        for (const Property in object1) {
            if (!(Property in object2)) {
                object2[Property] = object1[Property];
            }
        }
    } else {
        if (debug) {
            console.error(`Los argumentos deben ser de tipo objeto`);
        }
    }
};

/**
 * Esta función permite codificar y decodificar en base64 recibe tres parámetros y
 * utiliza las funciones btoa(), atob(), encodeURIComponent() y decodeURIComponent().
 * El primer parámetro es la información a codificar o decodificar, también valida el tipo de dato para saber si debe utilizar el JSON.stringify()
 * el segundo parámetro se usa para saber si codifica o decodifica en base64
 * y como tercer parámetro es de tipo boolean para saber si utiliza el encodeURIComponent() o decodeURIComponent()
 * depende del segundo parámetro
 *
 * @param {String} data valor a codificar o decodificar
 * @param {Boolean} b64 valor string "encode" para codificar btoa(), "decode" para decodificar atob()
 * por defecto es "encode"
 * @param {Boolean} uriComponent valor booleano verdadero para codificar encodeURIComponent() o decodificar decodeURIComponent() dependiendo del argumento en el parámetro b64, por defecto es true, si uriComponent es false no se utiliza
 * @param {Boolean} Mostrar warnings para depurar.
 * @returns {String} devuelve la cadena codificada o decodificada.
 */

export const b64Uri = (
    data,
    b64 = "encode",
    uriComponent = true,
    debug = false
) => {
    if (
        data === null ||
        data === undefined ||
        (getType(data) === "string" && data.trim() === "")
    ) {
        if (debug) {
            console.warn("No hay datos para codificar");
        }
        return;
    }

    if (getType(data) === "object" || getType(data) === "array") {
        data = JSON.stringify(data);
    }

    if (b64 === "encode") {
        if (uriComponent) {
            data = btoa(encodeURIComponent(data));
        } else {
            data = btoa(data);
        }
    } else {
        try {
            if (uriComponent) {
                data = decodeURIComponent(atob(data));
            } else {
                data = atob(data);
            }
        } catch (error) {
            data = null;
            if (debug) {
                console.warn(
                    `Parece que quieres decodificar una cadena que no está codificada \n${error}`
                );
            }
        }
    }

    return data;
};

/**
 * Mostrar notificaciones del sistema usando SweetAlert2
 *
 * @param {String} htmlText Mensaje de aviso puede ser código html
 * @param {String} icon Icono del aviso por defecto es error
 * @param {Int} timer Cuenta atrás para autocierre de aviso por defecto 0
 * @param {Bool} closeButton Mostrar el botón de cerrar notificación por defecto es tru
 * @param {String} bg Color de fondo es rojo
 */
export const alertHTML = (
    htmlText,
    icon = "error",
    timer = 10000,
    closeButton = true,
    bg = "#FEDCDC"
) => {
    let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timerProgressBar: true,
    });

    if (icon !== "error") {
        bg = "";
    }

    Toast.fire({
        icon: icon,
        background: bg,
        padding: "0.3rem",
        showCloseButton: closeButton,
        timer: timer,
        html: htmlText,
    });
};

/**
 * Retornar el valor de un atributo de un elemento dado
 *
 * @param {*} element Identificador, clase o elemento HTML
 * @returns {string} Valor del atributo
 */
export const getData = (element, attribute = "value", dom = document) => {
    if (attribute === "value") {
        return getElement(element, dom)?.value;
    } else {
        return getElement(element, dom)?.getAttribute(attribute);
    }
};

/**
 * Obtener el elemento objetivo
 *
 * @param {*} element Identificador, clase o elemento HTML
 * @param {Boolean} Mostrar warnings para depurar.
 * @returns {HTMLElement} Devuelve un elemento del DOM
 */
export const getElement = (target, dom = document, debug = false) => {
    let element = target;

    if (getType(target) === "string" && dom) {
        element = dom.querySelector(target);
        if (!element && !target.match(/^\[/)) {
            if (!element) {
                element = dom.querySelector("#" + target);
            }

            if (!element) {
                element = dom.querySelector("." + target);
            }
        }
    }

    if (!element && debug) {
        console.warn(`No existe el elemento ${target}`);
    }

    return element;
};

/**
 * Comparar la instancia dada.
 *
 * @param {*} element Identificador, clase o elemento HTML
 * @returns {boolean} Devuelve true o false
 */
export const getInstance = (element, instance) => {
    element = getElement(element);

    return element instanceof instance;
};

/**
 * Resetear Formulario
 *
 * @param {*} form Identificador, clase o elemento HTML de tipo FORM
 * @param {string} title Título a cambiar en el modal
 * @param {Boolean} Mostrar warnings para depurar.
 * @param {string} url Url para realizar la petición
 */
export const resetForm = (form, title, url, debug = false) => {
    form = getElement(form);

    if (getInstance(form, HTMLFormElement)) {
        form.reset();
        form.setAttribute("action", url);
        const Title = form.querySelector(".modal-title");

        if (Title) {
            Title.textContent = title;
        }

        // Estos foreach podrían ser cuestionables
        form.querySelectorAll("select[is^=select-]")?.forEach((item) => {
            resetSelect2Ajax(item);
        });

        form.querySelectorAll("div[is=input-image]")?.forEach((item) => {
            const Img = item.firstElementChild.firstElementChild;
            Img.src =
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        });
    } else {
        if (debug) {
            console.warn(
                "Para poder resetear un formulario es necesario un elemento de tipo 'form'"
            );
        }
    }
};

/**
 * Recargar las secciones aside y main de la página
 *
 * @param {function} callbak Función para ejecutar
 */
export const reloadSection = () => {
    fetch(location.href)
        .then((response) => response.text())
        .then((response) => {
            const doc = new DOMParser().parseFromString(response, "text/html");
            document
                .querySelector("html")
                .setAttribute(
                    "lang",
                    doc.querySelector("html").getAttribute("lang")
                );

            for (const query of ["main", "aside"]) {
                const old = document.querySelector(query);
                const nuevo = doc.querySelector(query);
                old.parentNode.replaceChild(nuevo, old);
            }
        });
};

/**
 * Setear un valor en el select2 simple y con ajax
 *
 * @param {*} select2 Elemento con instancia select2
 * @param {*} value Nuevo valor
 * @param {*} text Nuevo texto
 * @param {*} data Toda la data del select2 en caso de ser necesario
 */
export const setSelect2Ajax = (
    select2,
    value = null,
    text = "",
    data = null
) => {
    let option = $("<option selected></option>").val(value).text(text);

    // En caso de usar templateSelection, se necesita toda
    if (data) {
        if (!data.text) {
            data.text = text;
        }
        option.data("data", data);
        select2.setAttribute("data-data-selected", b64Uri(data));
    }

    $(getElement(select2)).append(option).trigger("change");
};

/**
 * Resetear la instancia select2 usando AJAX
 *
 * @param {*} select2 Elemento html
 */
export const resetSelect2Ajax = (select2) => {
    select2 = getElement(select2);
    $(select2).empty();
    select2?.removeAttribute("data-default");
    select2?.removeAttribute("data-data-selected");
};

/**
 * Obtener la data del select2
 * @param {*} select2
 */
export const getDataSelect2 = (select2, dom = document) => {
    try {
        return $(getElement(select2, dom)).select2("data")[0];
    } catch (error) {
        console.warn("Aún no está cargado el select2");
        return {};
    }
};

/**
 *
 * @param {*} element
 * @returns Promise
 */
export const sleep = (timer = 500) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, timer);
    });

/**
 * Realizar petición para establecer un valor por defecto
 */
export const defaultSelect2 = async (select2, search, url) => {
    bodyRequest.set("search", search);
    let response = await fetch(getData(url), requestOptions);
    let data = await response.json();
    if (data.length) {
        setSelect2Ajax(select2, data[0].id, data[0].text);
        // select2.setAttribute("data-data-selected", b64Uri(data[0]));
    }
};

/**
 * Crear un formulario para realizar envíos POST, si parámetro submit es falso
 * retorna el formulario completo.
 *
 * @param {string} url
 * @param {object} values
 * @param {string} formId
 * @param {string} method
 * @param {string} submit
 * @returns
 */
export const createForm = (
    url,
    values = {},
    formId = "dinamicForm",
    method = "POST",
    submit = true,
    target = null
) => {
    const Form = document.createElement("form");
    const InputToken = document.createElement("input");
    Form.setAttribute("class", "d-none dynamicForm");
    Form.setAttribute("method", method);
    Form.setAttribute("action", url);

    if (target) {
        Form.setAttribute("target", target);
    }

    const dynamicFormCount = document.querySelectorAll(".dynamicForm");

    Form.setAttribute("id", `${formId}_${dynamicFormCount.length}`);

    InputToken.setAttribute("type", "hidden");
    InputToken.setAttribute("name", "_token");
    InputToken.setAttribute("value", CSRF);

    Form.appendChild(InputToken);

    for (const item in values) {
        if (Array.isArray(values[item])) {
            values[item].forEach((value) => {
                const InputForm = document.createElement("input");
                InputForm.setAttribute("name", item + "[]");
                InputForm.setAttribute("value", value);
                Form.appendChild(InputForm);
            });
        } else {
            const InputForm = document.createElement("input");
            InputForm.setAttribute("name", item);
            InputForm.setAttribute("value", values[item]);
            Form.appendChild(InputForm);
        }
    }

    document.querySelector("body").appendChild(Form);

    if (submit) {
        Form.submit();
    } else {
        return Form;
    }
};

/**
 * Realizar pregunta de confirmación para su eliminación
 *
 * @param {string} title
 * @param {string} text
 * @param {string} url
 * @param {string} confirmButtonText
 * @param {string} cancelButtonText
 * @param {string} confirmButtonColor
 * @param {string} cancelButtonColor
 */
export const confirmDelete = (
    title,
    text,
    url,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor = "#d33",
    cancelButtonColor = "#3085d6"
) => {
    Swal.fire({
        title: title,
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.value) {
            createForm(url);
        }
    });
};

/**
 * Realizar pregunta de confirmación
 *
 * @param {string} title
 * @param {string} text
 * @param {string} url
 * @param {object} data
 * @param {string} confirmButtonText
 * @param {string} cancelButtonText
 * @param {string} confirmButtonColor
 * @param {string} cancelButtonColor
 */
export const confirmAction = (
    title,
    text,
    url,
    data = {},
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor = "#3085d6",
    cancelButtonColor = "#d33"
) => {
    Swal.fire({
        title: title,
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.value) {
            createForm(url, data);
        }
    });
};

/**
 * Intenta convertir un string en JSON si es correcto lo devuelve
 *
 * @param {string} value Valor codificado
 * @param {Boolean} Mostrar warnings para depurar.
 * @returns JSON
 */
export const getJson = (value, debug = false) => {
    try {
        value = JSON.parse(value);
    } catch (error) {
        value = { error: true };
        if (debug) {
            console.warn(`El valor no tiene formato JSON`);
        }
    }

    return value;
};

/**
 * Mostrar/Ocultar el elemento
 *
 * @param {*} element  Identificador, clase o elemento HTML
 */
export const showOrHide = (element, boolean = true) => {
    element = getElement(element);
    if (boolean) {
        element.classList.remove("d-none");
    } else {
        element.classList.add("d-none");
    }
};

/**
 * Dar formato de teléfono al input text (AVISO: en caso que el country no tenga digitos definidos,
 * solo mostrará el código de área) necesario bootstrap5 y directorios con png de banderas
 *
 * @param {object} country Objeto del modelo Country
 * @param {HTMLImageElement} flag Etiqueta img dentro de la estructura input-group de bootstrap
 */
export const formatTel = (country, flag) => {
    let src = flag.src.split("/");
    src.pop();
    src.push(country.flag + ".png");
    src = src.join("/");
    flag.src = src;

    let targetInputElement = flag.parentElement.nextElementSibling;
    let newFormat = "(+" + ("" + country.areaCode) + ") ";
    let newPlaceholder = "(+" + country.areaCode + ") ";

    // Crear formato de teléfono en base a 3 dígitos
    let tag = "";

    for (let i = 1; i <= country.digits; i++) {
        tag += "#";

        if (i % 3 === 0) {
            tag += "-";
        }
    }

    tag = tag.split("-");

    if (tag[tag.length - 1]) {
        // Si la longitud menos 1 es mayor o igual a la longitud del último elemento
        if (tag.length - 1 >= tag[tag.length - 1].length) {
            // recorrer en base a la longitud del último elemento y repartirlo hacia atrás
            for (let i = 1; i <= tag[tag.length - 1].length; i++) {
                tag[tag.length - i] += "#";
            }

            // Eliminar el último elemento
            tag.pop();
        }
    } else {
        // Si el último elemento está vacío eliminarlo
        tag.pop();
    }

    tag = tag.join("-"); // crear el string
    newFormat += tag; // finalizar el formato
    tag = tag.replace(/#/g, "_"); // crear el string para el placeholder
    newPlaceholder += tag; // finalizar el placeholder

    targetInputElement.setAttribute("placeholder", newPlaceholder);

    const phoneMask = new inputmask(newFormat);
    phoneMask.definitions.x = phoneMask.definitions["9"];
    delete phoneMask.definitions[9];

    phoneMask.mask(targetInputElement);
};

/**
 * Formatear el texto de tipo número, permite negativos y el separador decimal puede ser "punto" o "coma"
 *
 * @param {*} value
 * @param {string} numberDecimals
 * @param {string} separator
 * @returns devuelve el valor formateado
 */
export const _isNumber = (
    value,
    numberDecimals = DecimalLength,
    separator = DecimalSeparator
) => {
    value = "" + value; // convertir a string
    value = value.replace(/^(0+)/g, "0"); // reemplazar multiples 0 por un 0
    let negative = ""; // necesario para convertir en negativo

    // comprobar si es negativo o positivo
    // para eliminar el 0 a la izquierda
    if (value[0] == "-") {
        negative = "-";
        if (value.length > 2 && value[1] == "0") {
            value = value.substr(2);
        }
    } else {
        if (value.length > 1 && value[0] == "0") {
            value = value.substr(1);
        }
    }

    value = value.replace(/[^\d.,]/gi, ""); // reemplazar todo excepto números, puntos y comas

    // proceso para utilizar tanto punto o coma como separador decimal
    let decimals = value.split(""); // convertir en array para detectar el separador decimal
    let dot = decimals.findIndex((item) => item == "."); // saber si utiliza punto devuelve el índice
    let comma = decimals.findIndex((item) => item == ","); // saber si es coma devuelve el índice
    let indexDecimal; // tomará el valor del separador
    let numberClean; // tomará el valor del número

    // si tiene decimales debe entrar aquí
    if (dot != comma) {
        // comprobar si existen uno o los dos separadores para saber cuál debe utilizar
        // el primero que encuentre será el separador a utilizar
        if (dot > -1 && comma > -1) {
            if (dot < comma) {
                indexDecimal = dot;
                value = value.replace(/,/g, "");
            } else {
                indexDecimal = comma;
                value = value.replace(/\./g, "");
            }
        } else {
            if (dot > comma) {
                indexDecimal = dot;
                value = value.replace(/,/g, "");
            } else {
                indexDecimal = comma;
                value = value.replace(/\./g, "");
            }
        }

        value = value.split(decimals[indexDecimal]); // separar los decimales de los enteros
        numberClean = (value[0] > 0 ? value[0] : 0) + separator; // obtener el número ó 0 antes del separador para agregarlo luego
        value.shift(); // eliminar el primer índice
        value = value[0].split(""); // dividir en array el índice con decimales para luego dejar los decimales necesarios

        // si la longitud del array con decimales es mayor a los decimales permitidos deben eliminar el último
        if (value.length > numberDecimals) {
            value.pop();
        }

        numberClean += value.join(""); // unir todo

        // asignar negativo
        if (negative) {
            numberClean = negative + numberClean;
        }
    }

    // asignar negativo
    if (negative) {
        value = negative + value;
    }

    return numberClean ?? value;
};

export const isNumber = (
    event,
    value,
    numberDecimals = DecimalLength,
    separator = DecimalSeparator
) => {
    let negative = "";
    // Permitir solo números, "-" al inicio, y separadores (".", ",")
    value = ("" + value).replace(
        new RegExp(`[^0-9${separator}${ThousandSeparator}-]`, "g"),
        ""
    );

    // evitar que comas y puntos continuos
    value = value.replace(",.", ",");

    // Permitir que el "-" esté solo al principio
    if (value.startsWith("-")) {
        negative = "-";
        // Si es solo "-", no formateamos nada más
        if (value.length === 1) {
            return value;
        }
    }

    // Asegurar que solo se permita un "-" al inicio
    value = value.replace(/-/g, ""); // Eliminar todos los "-"

    // Manejo de la parte entera y decimal
    let [integerPart, decimalPart = ""] = value.split(separator);

    // Remover los separadores de miles de la parte entera
    integerPart = integerPart.replace(
        new RegExp(`\\${ThousandSeparator}`, "g"),
        ""
    );

    // Formatear la parte entera con separadores de miles
    integerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ThousandSeparator
    );

    // cuando se escriba decimales directamente agregar 0
    if (integerPart == "") {
        integerPart = 0;
    }

    // Limitar la parte decimal a la cantidad de decimales permitidos
    if (decimalPart.length > numberDecimals) {
        decimalPart = decimalPart.slice(0, numberDecimals);
    }

    // Unir la parte entera y decimal de nuevo
    if (
        value.includes(separator) ||
        (event.inputType === "insertText" &&
            (event.data === "," || event.data === "."))
    ) {
        if (decimalPart.endsWith(".") || decimalPart.endsWith(",")) {
            decimalPart = decimalPart.slice(0, -1);
        }

        value = integerPart + separator + decimalPart;
    } else {
        value = integerPart;
    }

    if (value == 0) {
        value = "";
    }

    // Si es un número negativo, mantener el "-" al principio
    value = negative + value;

    return value;
};

/**
 * Permitir solo números enteros negativos o positivos
 *
 * @param {*} value
 * @param {string} signal
 * @returns
 */
export const onlyInteger = (value, signal = null) => {
    let negative = "";
    value = negative + value; // convertir a string

    switch (signal) {
        case "positive":
            negative = "";
            break;

        case "negative":
            negative = "-";
            break;

        default:
            if (value[0] == "-") {
                negative = "-";
            } else {
                negative = "";
            }
            break;
    }

    value = value.replace(/[^\d]/gi, ""); // reemplazar todo excepto números

    if (value.length) {
        value = parseInt(value);
    }

    return negative + value;
};

/**
 * eliminar ceros sin valor
 *
 * @param {*} value
 * @param {*} separator
 * @returns
 */
export const cleanNumberInput = (value, separator = DotSeparator) => {
    value = "" + value;
    if (value.indexOf(separator) >= 0) {
        value = value.replace(/0+$/, "");
    }

    if (value.indexOf(separator) == value.length - separator.length) {
        value = value.substr(0, value.length - separator.length);
    }

    return value;
};

// Devolver un número decimal
export const cleanFloat = (value) => {
    value = value
        ? parseFloat(
              value
                  .replace(new RegExp(`\\${ThousandSeparator}`, "g"), "")
                  .replace(CommaSeparator, DotSeparator),
              "10"
          )
        : 0;

    return value;
};

// Formatear moneda
export const formatCurrency = (
    number,
    style = "currency",
    locales = LocalCurrency,
    currency = Currency,
    fractionDigits = DecimalLength
) =>
    new Intl.NumberFormat(locales, {
        style: style,
        currency: currency,
        minimumFractionDigits: fractionDigits,
        useGrouping: true,
    }).format(number);

/**
 * Limpiar moneda
 */
export const clearCurrency = (value) => {
    const decDot = value.lastIndexOf(".") > value.lastIndexOf(",");
    value = value.replaceAll(decDot ? "," : ".", "");
    if (!decDot) value = value.replace(/,/g, ".");
    value = value.replace(/[^0-9,.-]/gu, "");
    return parseFloat(value);
};

/**
 * Copiar valor de un input a otro
 *
 * @param {HTMLInputElement} elementPaste
 */
export const copyPaste = (elementPaste) => {
    let ctn = getElement(getData(elementPaste, "data-parent")) ?? document;

    let value = ctn.querySelector(
        "[data-copy=" + elementPaste.getAttribute("data-paste") + "]"
    )?.value;

    elementPaste.value = value;
};

/**
 * Validar el formato del email
 *
 * @param {HTMLInputElement} email Se espera un elemento input de tipo email
 * @param {Boolean} required (Opcional por defecto es falso) Si es verdadero significa que es requerido, (no podría estar vacío)
 * @returns
 */
export const validateEmail = (email, required = false) => {
    email = getElement(email);
    const re = /^[-\w.]{1,100}@(?:[A-Z0-9-]{1,100}\.){1,125}[A-Z]{2,63}$/i;
    let validated = true;

    email.setCustomValidity("");
    // email.classList.remove("is-invalid");

    if ((required || email.value.trim()) && !re.test(email.value.trim())) {
        // email.classList.add("is-invalid");
        email.setCustomValidity(
            "La dirección de correo electrónico no es válida"
        );
        validated = false;
    }

    return validated;
};

/**
 * Validar el formato del número telefónico
 *
 * @param {HTMLInputElement} tel Se espera un elemento input de tipo text
 * @param {Boolean} required (Opcional por defecto es falso) Si es verdadero significa que es requerido, (no podría estar vacío)
 * @returns
 */
export const validateTel = (tel, required = false) => {
    tel = getElement(tel);
    const re =
        /^[(][+][0-9]{1,5}[)][ ][\d]{2,5}[-][\d]{3,5}([-][\d]{3,5})?([-][\d]{3,5})?$/;
    let validated = true;

    tel.setCustomValidity("");
    // tel.classList.remove("is-invalid");

    if ((required || tel.value.trim()) && !re.test(tel.value.trim())) {
        // tel.classList.add("is-invalid");
        tel.setCustomValidity("El formato del teléfono no es válido");
        validated = false;
    }

    return validated;
};

/**
 * Función para comprobar si el elemento clickeado está deshabilitado
 *
 * @param {HTMLElement} element
 * @returns
 */
export const isDirectlyDisabled = (element) => {
    if (!element) return false;
    if (element.classList.contains("disabled")) return true;

    // Comprobar solo los descendientes directos, no los ancestros,
    // esto porque si está deshabilitado el evento se propaga al padre más cercano
    for (let child of element.children) {
        if (child.classList.contains("disabled")) {
            return true;
        }
    }
    return false;
};

/**
 * Traducir a través de JavaScript
 *
 * @param {*} lang
 * @returns
 */
export const readTranslations = async (lang = getData("html", "lang")) => {
    if (isNaN(lang)) {
        bodyRequest.set("search", lang);
        const response = await fetch(getData("langsUrlSearch"), requestOptions);
        const data = await response.json();
        lang = data.id;
    }

    const request = await fetch(
        getData("readTranslationsUrl") + lang,
        requestOptions
    );
    const response = await request.json();

    return response;
};

/**
 * Identificar el tab seleccionado para recordarlo entre cambios y reloads
 *
 * @param {*} tab
 */
export const tabs = (tab = null) => {
    const Tabs = getJson(localStorage.getItem("tabs")) ?? [];
    let url = window.location.href;

    let data = Tabs.find((item) => item.url == url);

    if (tab) {
        if (data) {
            data.id = tab.id;
        } else {
            Tabs.push({ url: url, id: tab.id });
        }

        localStorage.setItem("tabs", JSON.stringify(Tabs));
    } else {
        if (data) {
            const Tab = getElement(data.id);
            Tab?.click();
        }
    }
};

export const elementClass = (
    element,
    _class = "is-invalid",
    action = "add"
) => {
    switch (action) {
        case "remove":
            element?.classList.remove(_class);
            break;
        case "replace":
            element?.setAttribute("class", _class);
            break;
        default:
            element?.classList.add(_class);
            break;
    }
};

export const getAccountingDefault = async (code) => {
    bodyRequest.set("code", code);
    const request = await fetch(getData("accountingByCodeUrl"), requestOptions);
    const response = await request.json();
    return response;
};

/**
 * Cargar la restricciones
 */
export const loadInputInteger = () => {
    const numberElement = document.querySelectorAll("[data-only-int]");

    numberElement.forEach((element) => {
        element.addEventListener("input", () => {
            element.value = onlyInteger(
                element.value,
                getData(element, "data-only-int")
            );
        });
    });
};

/**
 * Cargar el evento focus
 */
export const loadCopyPaste = () => {
    const numberElement = document.querySelectorAll("[data-paste]");

    numberElement.forEach((element) => {
        element.addEventListener("focus", () => {
            if (!element.value) {
                copyPaste(element);
            }
        });
    });
};

/**
 * Realizar peticiones de espera con manejo de errores
 */
export const tryAsyncFetch = async (url, requestOptions) => {
    try {
        const response = await fetch(url, requestOptions);

        return await response.json();
    } catch (error) {
        return { errors: error };
    }
};

/**
 * Retornar el primer caracter que no sea alfanumérico
 * @param {*} string
 * @returns String || Null
 */
export const firstEspecialChar = (string) => {
    const regex = /[^a-zA-Z0-9]/;
    const match = string.match(regex);
    return match ? match[0] : null;
};

// Validar fecha
export const isDate = (date) => {
    date = new Date(date);
    return !isNaN(date.getTime());
};

export const getDate = (data) => {
    return moment(data);
};

export const formDataToObject = (form, buttons = false) => {
    let formObject = {};

    // Recorrer todos los elementos del formulario
    Array.from(form.elements).forEach((element) => {
        // Solo elementos con atributo "name"
        if (element.name) {
            if (
                element.tagName.toLowerCase() == "button" ||
                element.type.toLowerCase() == "button"
            ) {
                if (buttons) {
                    formObject[element.name] = "";
                }
            } else {
                if (element.name.endsWith("[]")) {
                    let name = element.name.slice(0, -2);
                    formObject[name] = [];
                } else {
                    formObject[element.name] = "";
                }
            }
        }
    });

    return formObject;
};

export const getDataFormToObject = (form, filter = {}) => {
    let dataForm = new FormData(form);
    
    let data = Array.from(dataForm).reduce((acc, [key, value]) => {
        if (key.endsWith("[]")) {
            // Remueve los corchetes del nombre de la clave para el objeto
            key = key.slice(0, -2);
            // Crea un array si aún no existe
            if (!acc[key]) {
                acc[key] = [];
            }
            // Añade el valor al array
            acc[key].push(value);
        } else {
            acc[key] = value;
        }
        return acc;
    }, filter);

    return data;
};

export const resetFilter = (form, filter) => {
    Array.from(form.elements).forEach((item) => {
        if (item.name) {
            if (item.name.endsWith("[]")) {
                let name = item.name.slice(0, -2);
                item.value = filter[name];
            } else {
                item.value = filter[item.name];
            }

            if (item.getAttribute("is")?.startsWith("select-")) {
                resetSelect2Ajax(item);
            }
        }
    });
};

/**
 * *************************************************************
 * *************************************************************
 * *************************************************************
 * *************************************************************
 */

/**
 * Solo Variables y constantes
 */
export const CSRF = getData("meta[name=csrf-token]", "content");

let myHeaders = new Headers();
myHeaders.append("X-CSRF-TOKEN", CSRF);
myHeaders.append("X-Requested-With", "XMLHttpRequest");

export let bodyRequest = new FormData();

export let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: bodyRequest,
};

export const DateFormat = getData("html", "data-dateFormat");
export const CommaSeparator = ",";
export const DotSeparator = ".";
export const LocalCurrency = "de-DE"; // por la RAE no se usa es-ES agrupación de miles
export const Currency = "EUR";
export const DecimalSeparator = CommaSeparator;
export const ThousandSeparator = DotSeparator;
export const DecimalLength = "2"; // Máximo 5 decimales
export const Management = getJson(b64Uri(getData("management"), "decode"));
moment.locale(getData(getElement("html"), "lang"));

// Ejecutar en carga inicial
window.addEventListener("load", () => {
    const Body = document.querySelector("body");
    setFocus("setFocus");
    loadInputInteger();
    loadCopyPaste();
    tabs();
    tippy(".tippy-tooltips");

    Body.addEventListener("click", async (e) => {
        const Target = e.target;
        // Notificar opción no disponible
        if (isDirectlyDisabled(Target)) {
            const Translations = await readTranslations();
            alertHTML(Translations["view.generic.unavailable"], "info", 1500);
        }
    });

    Body.addEventListener("input", (e) => {
        const InputNumber = e.target.closest("[data-number]");

        if (InputNumber) {
            let signal = InputNumber.getAttribute("data-signal");

            // No me gusta mucho esta lógica pero funciona
            if (signal) {
                if (signal == "-") {
                    if (!InputNumber.value.startsWith(signal)) {
                        InputNumber.value *= -1;
                    }
                } else {
                    if (InputNumber.value.startsWith("-")) {
                        InputNumber.value *= -1;
                    }
                }
            }

            InputNumber.value = isNumber(e, InputNumber.value);
        }
    });

    Body.addEventListener("focusout", (e) => {
        const InputNumber = e.target.closest("[data-number]");

        if (InputNumber) {
            InputNumber.value = cleanNumberInput(
                InputNumber.value,
                DecimalSeparator
            );
        }
    });

    document.addEventListener("focusin", (e) => {
        const Target = e.target;
        const inputSelect =
            Target.parentElement?.parentElement?.parentElement
                ?.firstElementChild;

        if (inputSelect?.getAttribute("is")?.startsWith("select-")) {
            $(inputSelect).select2("open");
        }

        if (Target.classList.contains("same-disabled")) {
            Target.blur();
        }
    });

    document.body
        .querySelectorAll(".enable-onload")
        .forEach((node) => node.classList.remove("disabled"));
});
