import { SelectDos } from "../plugins/select2";
import { getData, CSRF, getJson, b64Uri, setSelect2Ajax } from "../helpers";

export const dataProject = { projects: [] };
/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectProject extends HTMLSelectElement {
    constructor() {
        super();
    }

    async connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("projectsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (params) {
                    // Simular scroll infinito
                    let page = params.page || 1;
                    return {
                        _token: CSRF,
                        search: params.term,
                        page: page,
                        projects: dataProject.projects,
                    };
                },
                processResults: function (response, params) {
                    // Simular scroll infinito
                    params.page = params.page || 1;
                    let more =
                        params.page * response.limit < response.total_count;
                    return {
                        results: response.items,
                        pagination: {
                            more: more,
                        },
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            setSelect2Ajax(this, def.id, def.name);
        }

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            if (!this.hasAttribute("data-not-filter")) {
                if (S[0].hasAttribute("multiple")) {
                    dataProject.projects.push(Data.id);
                } else {
                    dataProject.projects = [Data.id];
                }
            }
        });

        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;

            if (!this.hasAttribute("data-not-filter")) {
                dataProject.projects = dataProject.projects.filter(
                    (item) => item != Data.id
                );
            }
        });
    }
}

window.customElements.define("select-project", SelectProject, {
    extends: "select",
});
