import { Dt } from "../../../../plugins/datatable";
import { CSRF, getData, getElement } from "../../../../helpers";

/**
 * El usuario
 */
class UserModule extends HTMLElement {
    connectedCallback() {
        this.test();
        this.load();
    }

    test() {
        // Ese método sirve solo para hacer pruebas
        // Borrar o comentar su contenido pero no el método
        // console.log(this);
    }

    load() {
        const DtElement = getElement("[data-table=dt]", this);

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "520px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("usersUrlDt"),
            type: "POST",
            beforeSend: (request) =>
                request.setRequestHeader("X-CSRF-TOKEN", CSRF),
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            {
                data: "photo",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            { data: "name", width: "15%" },
            { data: "email", width: "17%" },
            { data: "access" },
            {
                data: "status",
                width: "7%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.drawCallback = () => {
            // tippy(".tippy-tooltips");
        };

        const DtCompanies = new Dt(configDt);
        DtCompanies.dataTable(DtElement);
        // End DataTable
    }
}

window.customElements.define("user-module", UserModule, {
    extends: "section",
});
