import { SelectDos } from "../plugins/select2";
import { getData, CSRF } from "../helpers";
// import ucwords from "ucwords";

export const dataShippingmethod = { shippingmethods: [] };
/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class Shippingmethod extends HTMLSelectElement {
    async connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("shippingmethodsUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        shippingmethods: dataShippingmethod.shippingmethods,
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);
        
        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;
            dataShippingmethod.shippingmethods.push(Data.id);
        });
        
        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;
            dataShippingmethod.shippingmethods =
                dataShippingmethod.shippingmethods.filter(
                    (item) => item != Data.id
                );
        });
    }
}

window.customElements.define("select-shippingmethod", Shippingmethod, {
    extends: "select",
});
